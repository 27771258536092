$brand-primary:         #263238;
$brand-secondary:       #f08108;
$brand-accent:          #0878f0;

$navbar-inverse-bg:   $brand-primary;
$navbar-inverse-link-active-color: #fff !default;

$btn-secondary-color:        #fff !default;
$btn-secondary-bg:           $brand-secondary !default;
$btn-secondary-border:       darken($btn-secondary-bg, 5%) !default;

$btn-accent-color:        #fff !default;
$btn-accent-bg:           $brand-accent !default;
$btn-accent-border:       darken($btn-accent-bg, 5%) !default;

@import "main";